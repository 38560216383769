import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Global } from "../../../helpers/Global";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser";
import listUser from "../../../services/users";
import listGranjas from "../../../services/granjas";
import { useSplash } from '../../../context/SplashContext'
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";
import listNaves from "../../../services/naves";


export const FormTareas = ({ accion }) => {

  const {jwt, isLogged, notification, user, logout} = useUser();

  const datosURL = useParams();
  const idTarea = datosURL.id;
  
  const [titleForm, setTitleForm] = useState(accion);

  const [checked, setChecked] = React.useState(true);

  const [granjaTarea, setGranjaTarea] = useState('');
  const [veterinarioTarea, setVeterinarioTarea] = useState('');
  const [estadoTarea, setEstadoTarea] = useState('');
  const [fechaPrevistaTarea, setFechaPrevistaTarea] = useState('');
  const [horaPrevistaTarea, setHoraPrevistaTarea] = useState('');
  const [asuntoTarea, setAsuntoTarea] = useState('');
  const [observacionesTarea, setObservacionesTarea] = useState('');
  const [ naveTarea, setNaveTarea ] = useState('');

  const [ borrado, setBorrado ] = useState('');

  const [granjas, setGranjas ] = useState('');
  const [usuarios, setUsuarios] = useState('');
  const [ naves, setNaves ] = useState('');

  const [ isLoading, setIsLoading ] = useState(false);

  const userRole = user.role;
 
  const granjaTareaOnchangeHandler = (evento) => {
    setGranjaTarea(evento.target.value);
  };
  const veterinarioTareaOnchangeHandler = (evento) => {
  setVeterinarioTarea(evento.target.value);
  };
  const estadoTareaOnchangeHandler = (evento) => {
    setEstadoTarea(evento.target.value);
  };
  const fechaPrevistaTareaOnchangeHandler = (evento) => {
    setFechaPrevistaTarea(evento.target.value);
  };
  const horaPrevistaTareaOnchangeHandler = (evento) => {
    setHoraPrevistaTarea(evento.target.value);
  };
  const asuntoTareaOnchangeHandler = (evento) => {
    setAsuntoTarea(evento.target.value);
  };
  const observacionesTareaOnchangeHandler = (evento) => {
    setObservacionesTarea(evento.target.value);
  };
  const naveTareaOnchangeHandler = (evento) => {
    setNaveTarea(evento.target.value);
  }

  const navigate = useNavigate();

  const submitTarea = async (evento) => {
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".tareaEdit");
    const data = new FormData(formElement);

    let url = "";

    if (idTarea) {
      url = Global.url + "tareas/update/" + idTarea;
    } else {
      url = Global.url + "tareas/create";
    }
    
    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json()
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        }else if(respuesta.status === 400){
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            })
          });
          setIsLoading(false);
          navigate("/tareas");
        }
        else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
          setIsLoading(false);
          navigate("/tareas");
        }
        return data
      })
      .then(
        (datos) => {
          if (datos.status === 201 ) {
              Store.addNotification({
                ...notification,
                id: " ",
                title:  datos.message,
                message: datos.message,
                type: "success",
              });
              setIsLoading(true)
              navigate("/tareas");
          }

        }
      ).catch(error => {
        console.error('There was an error!', error);
    });
    
  };


  const getTareaData = () => {
    fetch(Global.url + "tarea/" + idTarea, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
    .then((respuesta) => {
      if(respuesta.status === 401){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout()
      }else if(respuesta.status === 500){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha ocurrido un error",
          type: "danger"
        });
        navigate("/tareas");
      }

      return respuesta.json()
    })
      .then(
        (resultado_final) => {
            setGranjaTarea(resultado_final.data.id_granja);
            setVeterinarioTarea(resultado_final.data.id_usuario);
            setEstadoTarea(resultado_final.data.id_estado);
            setFechaPrevistaTarea(resultado_final.data.fecha_prevista_formato);
            
            setAsuntoTarea(resultado_final.data.nombre);
            setObservacionesTarea(resultado_final.data.descripcion);
            setHoraPrevistaTarea(resultado_final.data.hora_prevista_formato);

            setNaveTarea(resultado_final.data.id_nave);
            
        },
        () => {
         // console.log(error);
        }
      );
  };
  

  const getUsuarios = () => {
    listUser(jwt).then(usuarios => {
        if(usuarios===401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
            logout();         
        }else{
            setUsuarios(usuarios);
        }
    })
  };

  const getGranjas = () => {
    listGranjas(jwt).then(granjas => {
      if(granjas === 401){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      }else {
        setGranjas(granjas);
      }
    })
  }

  const getNaves = () => {
    if(granjaTarea !== ""){
      listNaves(jwt, granjaTarea ).then(naves => {
        if (naves === 401 ){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else {
          setNaves(naves)
        }
      })
    } else {
      setNaves('')
    }
  }


  useEffect(() => {
    if (userRole === '1') {
      getUsuarios();
    }
    getGranjas();
  }, []) 

  useEffect(() => {
      getNaves();
  }, [granjaTarea]) 


  useEffect(() => {
    if(accion === "update"){
      getTareaData();
    } 
  },[accion]);


  const eliminarTarea = async (tarea) => {

    const request = await fetch(Global.url + "tareas/delete/" + tarea, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + jwt
      }
    });
    
    const datos = await request.json();

    if (datos.status === 201 ) {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Tarea eliminada",
        message: "Se ha eliminado la tarea correctamente.",
        type: "success",
      });
      setBorrado(tarea);
      navigate("/tareas")
    } else {
      setBorrado(tarea);
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "No se ha podido borrar la tarea. Por favor vueva a intentarlo.",
        type: "danger",
      });
    }
  };

  const borrarTarea = ({e, idTarea}) => {
    e.preventDefault();
    if(window.confirm("Vas a borrar la tarea, " + idTarea +"¿Quieres continuar?")){
      eliminarTarea(idTarea)
    }
  }

  return (
    <div>
      { isLoading === true ? <LoadingSpinner /> : '' }
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <h5 className="">
          {titleForm === "update" ? (
            <span className="spanTitulo">Actualizar tarea:</span>
          ) : (
            <span className="spanTitulo">Crear nueva tarea:</span>
          )}
        </h5>
        <br />
        <div className="contenedor-formulario">
          <form className="row g-3 tareaEdit" onSubmit={submitTarea}>
            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_granja">Seleccione granja: *</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_granja"
                        name="id_granja"
                        value={granjaTarea}
                        onChange={granjaTareaOnchangeHandler}
                        required >
                  <option >Selecciona granja</option>
                  {
                    granjas && granjas.map(granja => {
                      return <option key={granja.id} value={granja.id}>{granja.nombre}</option> 
                    })
                  }
                </select>
              </div></div>

              <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="id_nave">Seleccione nave:</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_nave"
                        name="id_nave"
                        value={naveTarea}
                        onChange={naveTareaOnchangeHandler}
                        >
                  <option value="">Selecciona nave</option>
                  {
                    naves && naves.map(nave => {
                      return <option key={nave.id} value={nave.id}>{nave.nombre}</option> 
                    })
                  }
                </select>
              </div></div>

            { userRole === '1' ? (<div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_usuario">Seleccione personal: *</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_usuario"
                        name="id_usuario"
                        value={veterinarioTarea}
                        onChange={veterinarioTareaOnchangeHandler}
                        required >
                  <option >Selecciona personal</option>
                  {
                    usuarios && usuarios.map(usuario => {
                      return <option key={usuario.id} value={usuario.id}>{usuario.nombre}</option> 
                    })
                  }
                </select>
              </div></div>) : (<input type="hidden" value={user.id} id="id_usuario" name="id_usuario" />)}

              <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="id_estado">Seleccione estado: *</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_estado"
                        name="id_estado"
                        value={estadoTarea}
                        onChange={estadoTareaOnchangeHandler}>
                  <option >Selecciona estado</option>
                    <option value='1'>Pendiente</option> 
                    <option value='2'>En curso</option> 
                    <option value='3'>Finalizada</option> 
                </select>
              </div></div>

              <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="fecha_prevista">Fecha prevista tarea: *</label>
              <input
                type="date"
                className="form-control"
                id="fecha_prevista"
                name="fecha_prevista"
                placeholder="Fecha de llegada de los animales"
                value={fechaPrevistaTarea}
                onChange={fechaPrevistaTareaOnchangeHandler}
                required
              />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="horaPrevistaTarea">Hora prevista tarea:</label>
              <input 
                type="time" 
                className="form-control"
                id="horaPrevistaTarea"
                name="horaPrevistaTarea"
                placeholder="Hora prevista de llegada de los animales"
                value={horaPrevistaTarea}
                onChange={horaPrevistaTareaOnchangeHandler}
                 />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="nombre">Asunto: *</label>
              <input
                type="text"
                className="form-control" 
                id="nombre"
                name="nombre"
                placeholder="Asunto"
                value={asuntoTarea}
                onChange={asuntoTareaOnchangeHandler}/>
            </div></div>

            <div className="col-md-12">
              <div className="form-group">
                  <label className="labelForm" htmlFor="descripcion">Descripción:</label>
                  <textarea
                    className="form-control" 
                    id="descripcion"
                    name="descripcion"
                    placeholder="Observaciones"
                    value={observacionesTarea}
                    onChange={observacionesTareaOnchangeHandler}/>
              </div>
            </div>
                {titleForm === "update" ? (
                 <div className="col-12">
                  <div className="form-group form-actions">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-perfil"
                        >Guardar</button>
                        <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block boton-azulbio btn-rojo boton-perfil"
                        onClick={(e) => {borrarTarea({e, idTarea})}}
                        >Borrar</button>
                  </div>
                 </div>
                ) : (
                  <div className="col-12">
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                 >Crear</button></div>
                )}    
          </form>
        </div>
      </div>
    </div>
  );
};
