import React from "react";
import { Link, useNavigate } from "react-router-dom";
import correo from "../../img/correo.svg";
import correoActivo from "../../img/correo-activo.svg";
import useUser from "../../hooks/useUser";
import BottomNavigation from 'reactjs-bottom-navigation';
import { HomeOutlined } from "@mui/icons-material";
import 'reactjs-bottom-navigation/dist/index.css'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';


export const Footer = () => {
  
  const { isLogged, logout } = useUser();

  const navigate = useNavigate();

  const bottomNavItems = [
    {
      title: 'Home',

      icon: <HomeOutlined style={{ fontSize: '22px' }} />,

      activeIcon: <HomeOutlined style={{ fontSize: '22px', color: '#fff' }} />,
      
      onClick: () => navigate('/dashboard')
    },

    {
      title: '(+34) 955 776 577',

      icon:  <a href="tel:+34955776577"><CallOutlinedIcon style={{ fontSize: '18px' }} /></a>,

      activeIcon: <a href="tel:+34955776577"><CallOutlinedIcon style={{ fontSize: '18px', color: '#fff' }} /></a>,

    },

   {
      title: 'tecnico@bioplagen.com',

      icon: <a href="mailto:tecnico@bioplagen.com"><EmailOutlinedIcon style={{ fontSize: '18px' }} /></a>,

      activeIcon: <a href="mailto:tecnico@bioplagen.com"><EmailOutlinedIcon style={{ fontSize: '18px', color: '#fff' }} /></a>
    },

    {
      title: 'Contacto',

      icon: <div><img src={correo} width="18" alt="contactar" /></div>,

      activeIcon: <img src={correoActivo} width="18" alt="contactar" />,

      onClick: () => navigate('/contacto')
    }, 
    {
      title: 'Cerrar sesión',

      icon: <LogoutOutlinedIcon style={{ fontSize: '22px' }} />,

      activeIcon: <LogoutOutlinedIcon style={{ fontSize: '22px' }} />,

      onClick: () => logout()
    }
  ]
  return (
    <div className="footer">
      { isLogged ? (<BottomNavigation
        items={bottomNavItems}
        selected={0}
      />):(<div className='footerPublic'>
      <a href="tel:+34955776577">(+34) 955 776 577</a> | 
      <Link
          to='#'
          onClick={(e) => {
              window.location.href = 'mailto:tecnico@bioplagen.com';
              e.preventDefault();
          }}
      >&nbsp; tecnico@bioplagen.com
      </Link>  
    </div>)}
    </div>
  );
};




