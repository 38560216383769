import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import borrar from "../../../img/borrar.svg";
import editar from "../../../img/editar.svg";
import { Link } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"

export const ListGranjas = ({busqueda, listado, filtro}) => {
  const { jwt, notification, user, logout} = useUser();

  const [granjas, setGranjas] = useState(listado);
  const [borrado, setBorrado] = useState(null);

  const userRole = user.role;

  const getGranjasFiltered = () => {
    setGranjas(()=>{
        
      let listadoGranjas = listado; 
      if(busqueda !== "") {
        listadoGranjas = listadoGranjas.filter(
                granja => {
                    return (
                        granja.nombre.toLowerCase().includes(busqueda.toLowerCase()) ||
                        granja.localizacion.toLowerCase().includes(busqueda.toLowerCase()) ||
                        granja.ganadero.toLowerCase().includes(busqueda.toLowerCase()) ||
                        granja.ganadero_email.toLowerCase().includes(busqueda.toLowerCase()) 
                    );
                }
            )
        }

        if(filtro !== "") {
          const types = {
            externa: 'bioexterna',
            interna: 'biointerna',
            media: 'biomedia',
           };
           const sortProperty = types[filtro];
           listadoGranjas = [...listadoGranjas].sort((a,b) => b[sortProperty] - a[sortProperty]);
        }
        
        return listadoGranjas;
    });
  };

  const eliminarGranja = async (granja, posicion) => {
      if (window.confirm("Vas a borrar la granja. ¿Quieres continuar?")){
            await fetch(Global.url + "granjas/delete/" + granja, {
              method: "DELETE",
              headers: { Authorization: "Bearer " + jwt },
            })
            .then((respuesta) => {
              const data = respuesta.json()
              if(respuesta.status === 401){
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Ha habido un error",
                  message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                  type: "danger",
                });
              logout()
            } else if(respuesta.status === 400){
              data.then((value) => {
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Ha habido un error",
                  message: value.errors[0].message,
                  type: "danger",
                })
              });
            } else if(respuesta.status === 500){
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: "Ha ocurrido un error",
                type: "danger",
              })
            }
            return data
            })
            .then((datos) => {
              if (datos.status === 201) {
                listado.splice(posicion, 1);
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Granja borrada",
                  message: "Granja borrada correctamente.",
                  type: "success",
                })
                setBorrado(granja);
            }  
            });
      }
  };

    useEffect(() => {
        getGranjasFiltered();
    }, [borrado, busqueda, listado, filtro]);

  return (
    <div className="contenedorListadoGranjas">
      {borrado === "error" ? (Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error al eliminar la granja, por favor vuelva a intentarlo.",
          type: "danger",
        })) : (
        ""
      )}

      {granjas &&
        granjas.map((granja, index) => {
          return (
           
            <Card
              className="listaVeterinarios"
              sx={{ minWidth: 300 }}
              key={index}
            >
              <CardContent>
                <Link to={"/granja/"+granja.id} >
                    <Typography variant="h5" color="text.principal" gutterBottom>
                      Nombre: {granja.nombre}
                    </Typography>
                </Link>
                <Typography sx={{ fontSize: 14 }} color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'>
                  Teléfono: {granja.ganadero_tlf}
                </Typography><br/>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'
                >
                  E-mail: {granja.ganadero_email}
                </Typography><br/>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'
                >
                  Bioseguridad externa: {granja.bioexterna}  {granja.bioexterna_total ? '/ ' + granja.bioexterna_total : ''}
                </Typography><br/>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'
                >
                  Bioseguridad interna: {granja.biointerna} {granja.biointerna_total ? '/ ' + granja.biointerna_total : ''}
                </Typography><br/>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'
                >
                  Bioseguridad media: {granja.biomedia} 
                </Typography><br/>
              </CardContent>
              <CardActions>
                <Button size="small">
                  <Link to={"/granja/"+granja.id} ><img src={editar} width="20" alt="editar" /></Link>
                </Button>
                { userRole === '1' ? (<Button
                  size="small"
                  onClick={(e) => eliminarGranja(granja.id, index)}
                  value={granja.id}
                >
                  <img
                    src={borrar}
                    width="20"
                    alt="borrar"
                    value={granja.id}
                  />
                </Button>) : ('')}
              </CardActions>
            </Card>
           
          );
        })}
    </div>
  );
};

