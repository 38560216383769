import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import listGranjas  from '../../services/granjas'
import useUser from "../../hooks/useUser"
import { ListGranjas } from "../modulos/granjas/List"
import { Store } from "react-notifications-component";

export const Granjas = () => {
    const { jwt, user, notification, logout } = useUser();
    const [searchField, setSearchField] = useState("");
    const [granjas, setGranjas] = useState("");

    const [filtroField, setFiltroField] = useState("");

    const filtroGranjasOnChangeHandler = (e) => {
        setFiltroField(e.target.value);
    }

    const userRole = user.role;

    const handleChange = (e) => {setSearchField(e.target.value); };

    const getGranjas = () => {
        listGranjas(jwt, 0).then(granjas => {
            if(granjas===401){
                Store.addNotification({
                    ...notification,
                    id: " ",
                    title: "Ha habido un error",
                    message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                    type: "danger",
                  });
                logout();         
            }else{
                setGranjas(granjas);
            }
        })
    };

    useEffect(() => {
        getGranjas();
    }, []);

    return (
    <div className="container-title">
     
      { !jwt ? <Navigate to="/login" /> : '' }

      <h5><span className="spanTitulo">Listado de granjas: </span></h5>

      <nav className="navbar navbar-light bg-light justify-content-around">
      <div className="form-group form-actions">
            { userRole === '1' ? (<button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-addUser" type="submit">
                <NavLink to="/granjas/crear" className="addUser" >Añadir granja</NavLink>
            </button>) : ('')}

          <form className="form-inline">
          <input
            className="form-control mr-sm-2 buscador"
            type="search"
            onChange = {handleChange}
            placeholder="Buscar"
            aria-label="Buscar"/>
        </form>

        </div>
      </nav>

            <div className="form-group form-filter">
                <label className="labelForm labelColum" htmlFor="filtrar">Ordenar por Bioseguridad:</label>
                <select className="form-select form-select form-control selectFilter" 
                        aria-label=".form-select-sm example"
                        id="filtrar"
                        name="filtrar"
                        value={filtroField}
                        onChange={filtroGranjasOnChangeHandler}
                       >
                  <option value="" >Seleccionar</option>
                  <option value="externa">Externa</option>
                  <option value="interna">Interna</option>
                  <option value="media">Media</option>
                </select>
            </div> 
     <ListGranjas busqueda={searchField} listado={granjas} filtro={filtroField} />
    </div>
    );
};